import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import TinyCards from 'components/Software/Android/TinyCam_Monitor/tinyCards';
import NavButtons from 'components/Software/Android/NavButtons';
import ForumBox from 'components/Software/Android/TinyCam_Monitor/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Android Software for your INSTAR IP Camera",
  "path": "/Software/Android/IP_Cam_Viewer/",
  "dateChanged": "2017-12-05",
  "author": "Mike Polinowski",
  "excerpt": "The Android app IP CamViewer for your Android tablet or smartphone. Control your camera when you don´t have access to your computer.",
  "image": "./P_SearchThumb_IP_Cam_Viewer.png",
  "social": "/images/Search/P_SearchThumb_IP_Cam_Viewer.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Android_IPCamViewer_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Android Software for your INSTAR IP Camera' dateChanged='2017-12-05' author='Mike Polinowski' tag='INSTAR IP Camera' description='The Android app IP CamViewer for your Android tablet or smartphone. Control your camera when you don´t have access to your computer.' image='/images/Search/P_SearchThumb_IP_Cam_Viewer.png' twitter='/images/Search/P_SearchThumb_IP_Cam_Viewer.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Android/TinyCam_Monitor/' locationFR='/fr/Software/Android/TinyCam_Monitor/' crumbLabel="TinyCam" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "android",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#android",
        "aria-label": "android permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Android`}</h2>
    <h3 {...{
      "id": "tinycam-monitor",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#tinycam-monitor",
        "aria-label": "tinycam monitor permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`TinyCam Monitor`}</h3>
    <p>{`To access your IP camera with the Android App `}<a href="http://tinycammonitor.com/" target="_blank" rel="noopener noreferrer">{`TinyCam Monitor`}</a>{` we recommend that you first download the `}<a href="https://play.google.com/store/apps/details?id=com.alexvas.dvr" target="_blank" rel="noopener noreferrer">{`Newest Version`}</a>{` from the app market. Use the search word "TinyCam" to find our app inside the Play Store.`}</p>
    <h3 {...{
      "id": "the-manage-cameras-window",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#the-manage-cameras-window",
        "aria-label": "the manage cameras window permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Manage Cameras Window`}</h3>
    <p>{`After you have installed the newest version please start the App and choose Manage Cameras to enter the configuration menu.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/010ab6e45fc477d9b2b403deb4803b67/eea4a/TinyCam-Monitor_01.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAGlJlNAkE//xAAbEAACAgMBAAAAAAAAAAAAAAABAgADEBETIf/aAAgBAQABBQInQVw2DfO8F/n/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAYEAADAQEAAAAAAAAAAAAAAAAAMqEQEf/aAAgBAQAGPwLurRBaf//EABoQAQACAwEAAAAAAAAAAAAAAAEAERBBcfD/2gAIAQEAAT8hJ1qIUGDAu9wHVOMDb5yf/9oADAMBAAIAAwAAABDzH//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPxBsf//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/EEf/xAAaEAEBAQEAAwAAAAAAAAAAAAABEQAhMbHh/9oACAEBAAE/EPGhl2jlrjtnZzRNBsfjWobSUR9adi//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/010ab6e45fc477d9b2b403deb4803b67/e4706/TinyCam-Monitor_01.avif 230w", "/en/static/010ab6e45fc477d9b2b403deb4803b67/d1af7/TinyCam-Monitor_01.avif 460w", "/en/static/010ab6e45fc477d9b2b403deb4803b67/7f308/TinyCam-Monitor_01.avif 920w", "/en/static/010ab6e45fc477d9b2b403deb4803b67/d7c22/TinyCam-Monitor_01.avif 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/010ab6e45fc477d9b2b403deb4803b67/a0b58/TinyCam-Monitor_01.webp 230w", "/en/static/010ab6e45fc477d9b2b403deb4803b67/bc10c/TinyCam-Monitor_01.webp 460w", "/en/static/010ab6e45fc477d9b2b403deb4803b67/966d8/TinyCam-Monitor_01.webp 920w", "/en/static/010ab6e45fc477d9b2b403deb4803b67/af3f0/TinyCam-Monitor_01.webp 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/010ab6e45fc477d9b2b403deb4803b67/e83b4/TinyCam-Monitor_01.jpg 230w", "/en/static/010ab6e45fc477d9b2b403deb4803b67/e41a8/TinyCam-Monitor_01.jpg 460w", "/en/static/010ab6e45fc477d9b2b403deb4803b67/4d836/TinyCam-Monitor_01.jpg 920w", "/en/static/010ab6e45fc477d9b2b403deb4803b67/eea4a/TinyCam-Monitor_01.jpg 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/010ab6e45fc477d9b2b403deb4803b67/4d836/TinyCam-Monitor_01.jpg",
              "alt": "TinyCam Monitor for your INSTAR IP Camera",
              "title": "TinyCam Monitor for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Choose Manage Cameras to add a new camera or adjust the camera settings like the camera name, login and IP configuration.`}</p>
    <p>{`Manage Cameras Window: The Manage Cameras Window allows you to add new cameras and change all the camera parameter you set when you added the camera to the app. Please come here in case a parameter has changed - e.g. the camera address changed or you choose a new password for the camera login.`}</p>
    <h3 {...{
      "id": "add-a-new-camera-manual",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-a-new-camera-manual",
        "aria-label": "add a new camera manual permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a new Camera (Manual)`}</h3>
    <p>{`The Add Camera button in the upper right lets you add your IP camera manually.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c217b34f5ac8a6c3ab3fefc9d9d34d54/eea4a/TinyCam-Monitor_02.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIBAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABwx4RSwP/xAAXEAEBAQEAAAAAAAAAAAAAAAAAEQEQ/9oACAEBAAEFAoiNXn//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAaEAACAgMAAAAAAAAAAAAAAAAAARARITFB/9oACAEBAAE/IZDVWNoVuCP/2gAMAwEAAgADAAAAEDw//8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8QV//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/EEf/xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhMVGx/9oACAEBAAE/EFLsEclvYBetO8gqvuIS6n//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c217b34f5ac8a6c3ab3fefc9d9d34d54/e4706/TinyCam-Monitor_02.avif 230w", "/en/static/c217b34f5ac8a6c3ab3fefc9d9d34d54/d1af7/TinyCam-Monitor_02.avif 460w", "/en/static/c217b34f5ac8a6c3ab3fefc9d9d34d54/7f308/TinyCam-Monitor_02.avif 920w", "/en/static/c217b34f5ac8a6c3ab3fefc9d9d34d54/d7c22/TinyCam-Monitor_02.avif 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c217b34f5ac8a6c3ab3fefc9d9d34d54/a0b58/TinyCam-Monitor_02.webp 230w", "/en/static/c217b34f5ac8a6c3ab3fefc9d9d34d54/bc10c/TinyCam-Monitor_02.webp 460w", "/en/static/c217b34f5ac8a6c3ab3fefc9d9d34d54/966d8/TinyCam-Monitor_02.webp 920w", "/en/static/c217b34f5ac8a6c3ab3fefc9d9d34d54/af3f0/TinyCam-Monitor_02.webp 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c217b34f5ac8a6c3ab3fefc9d9d34d54/e83b4/TinyCam-Monitor_02.jpg 230w", "/en/static/c217b34f5ac8a6c3ab3fefc9d9d34d54/e41a8/TinyCam-Monitor_02.jpg 460w", "/en/static/c217b34f5ac8a6c3ab3fefc9d9d34d54/4d836/TinyCam-Monitor_02.jpg 920w", "/en/static/c217b34f5ac8a6c3ab3fefc9d9d34d54/eea4a/TinyCam-Monitor_02.jpg 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c217b34f5ac8a6c3ab3fefc9d9d34d54/4d836/TinyCam-Monitor_02.jpg",
              "alt": "TinyCam Monitor for your INSTAR IP Camera",
              "title": "TinyCam Monitor for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Press the Add Camera button in the upper right of the Cameras tab to add your cameras.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/dab0ac63623d50a74e86512efdecf1c3/eea4a/TinyCam-Monitor_04.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHHTkRYn//EABgQAAMBAQAAAAAAAAAAAAAAAAABERAh/9oACAEBAAEFApyDQ2XP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFRABAQAAAAAAAAAAAAAAAAAAEAH/2gAIAQEABj8CY//EABkQAAMBAQEAAAAAAAAAAAAAAAARIQFBUf/aAAgBAQABPyFEgnhQRkcMfhlP/9oADAMBAAIAAwAAABDYP//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/EFf/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPxBH/8QAHBAAAwADAAMAAAAAAAAAAAAAAAERITFhQZGx/9oACAEBAAE/EHRaNzLhxehTxUXDzxa3oon0MksP/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dab0ac63623d50a74e86512efdecf1c3/e4706/TinyCam-Monitor_04.avif 230w", "/en/static/dab0ac63623d50a74e86512efdecf1c3/d1af7/TinyCam-Monitor_04.avif 460w", "/en/static/dab0ac63623d50a74e86512efdecf1c3/7f308/TinyCam-Monitor_04.avif 920w", "/en/static/dab0ac63623d50a74e86512efdecf1c3/d7c22/TinyCam-Monitor_04.avif 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/dab0ac63623d50a74e86512efdecf1c3/a0b58/TinyCam-Monitor_04.webp 230w", "/en/static/dab0ac63623d50a74e86512efdecf1c3/bc10c/TinyCam-Monitor_04.webp 460w", "/en/static/dab0ac63623d50a74e86512efdecf1c3/966d8/TinyCam-Monitor_04.webp 920w", "/en/static/dab0ac63623d50a74e86512efdecf1c3/af3f0/TinyCam-Monitor_04.webp 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dab0ac63623d50a74e86512efdecf1c3/e83b4/TinyCam-Monitor_04.jpg 230w", "/en/static/dab0ac63623d50a74e86512efdecf1c3/e41a8/TinyCam-Monitor_04.jpg 460w", "/en/static/dab0ac63623d50a74e86512efdecf1c3/4d836/TinyCam-Monitor_04.jpg 920w", "/en/static/dab0ac63623d50a74e86512efdecf1c3/eea4a/TinyCam-Monitor_04.jpg 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/dab0ac63623d50a74e86512efdecf1c3/4d836/TinyCam-Monitor_04.jpg",
              "alt": "TinyCam Monitor for your INSTAR IP Camera",
              "title": "TinyCam Monitor for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Type in a name for your camera, choose the camera vendor and model and add your camera´s IP address OR DDNS address and access port.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6056b5a6c15cc5cd11a0ae342c02b3df/eea4a/TinyCam-Monitor_10.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIBAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAByFlRSws//8QAFxAAAwEAAAAAAAAAAAAAAAAAAAEREP/aAAgBAQABBQKEGhsuf//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABUQAQEAAAAAAAAAAAAAAAAAABAB/9oACAEBAAY/AmP/xAAZEAADAQEBAAAAAAAAAAAAAAAAAREhQVH/2gAIAQEAAT8hiLER4aEFjgr4LT//2gAMAwEAAgADAAAAEFsf/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8QV//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/EEf/xAAdEAADAAAHAAAAAAAAAAAAAAAAAREhMUFRYZGx/9oACAEBAAE/EHSdJscPoU8KiNeLWZRPQyStH//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6056b5a6c15cc5cd11a0ae342c02b3df/e4706/TinyCam-Monitor_10.avif 230w", "/en/static/6056b5a6c15cc5cd11a0ae342c02b3df/d1af7/TinyCam-Monitor_10.avif 460w", "/en/static/6056b5a6c15cc5cd11a0ae342c02b3df/7f308/TinyCam-Monitor_10.avif 920w", "/en/static/6056b5a6c15cc5cd11a0ae342c02b3df/d7c22/TinyCam-Monitor_10.avif 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6056b5a6c15cc5cd11a0ae342c02b3df/a0b58/TinyCam-Monitor_10.webp 230w", "/en/static/6056b5a6c15cc5cd11a0ae342c02b3df/bc10c/TinyCam-Monitor_10.webp 460w", "/en/static/6056b5a6c15cc5cd11a0ae342c02b3df/966d8/TinyCam-Monitor_10.webp 920w", "/en/static/6056b5a6c15cc5cd11a0ae342c02b3df/af3f0/TinyCam-Monitor_10.webp 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6056b5a6c15cc5cd11a0ae342c02b3df/e83b4/TinyCam-Monitor_10.jpg 230w", "/en/static/6056b5a6c15cc5cd11a0ae342c02b3df/e41a8/TinyCam-Monitor_10.jpg 460w", "/en/static/6056b5a6c15cc5cd11a0ae342c02b3df/4d836/TinyCam-Monitor_10.jpg 920w", "/en/static/6056b5a6c15cc5cd11a0ae342c02b3df/eea4a/TinyCam-Monitor_10.jpg 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6056b5a6c15cc5cd11a0ae342c02b3df/4d836/TinyCam-Monitor_10.jpg",
              "alt": "TinyCam Monitor for your INSTAR IP Camera",
              "title": "TinyCam Monitor for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Add your username and password to allow the app to login to your camera.`}</p>
    <p>{`Please provide the following data to add your camera:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Camera Name`}</strong>{`: You can choose a name for your camera - the name does not have to be identical with the camera name inside the web user interface.`}</li>
      <li parentName="ul"><strong parentName="li">{`Camera Model`}</strong>{`: TinyCam allows you to add cameras from different manufacturers beside INSTAR. Please choose the correct brand and model of your camera.`}</li>
      <li parentName="ul"><strong parentName="li">{`Hostname/IP Address`}</strong>{`: Type your camera's local IP address (e.g. 192.168.178.26) to access your camera at home or use your DDNS address (e.g. abcd12.ddns-instar.de) when you want to be able to access your camera from the road. Please be aware that you will need a port forwarding rule inside your internet router to use the DDNS service! You do not need to add the camera's port here if your camera uses a http port different than the default 80.`}</li>
      <li parentName="ul"><strong parentName="li">{`Port`}</strong>{`: Type in the camera's http port, that can be found and set in the camera's web user interface under Network/IP Configuration. The default value is 80.`}</li>
      <li parentName="ul"><strong parentName="li">{`Username`}</strong>{`: To access your camera you will need to provide the username you use to log in to your camera's web user interface. The default username for both HD and VGA cameras is admin.`}</li>
      <li parentName="ul"><strong parentName="li">{`Password`}</strong>{`: Provide the password you use to log in to your camera's web user interface. The default password is instar for HD cameras and no password for VGA cameras.`}</li>
    </ul>
    <h3 {...{
      "id": "adjust-general-settings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#adjust-general-settings",
        "aria-label": "adjust general settings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adjust General Settings`}</h3>
    <p>{`The General Settings allow you to adjust certain video settings globally as well as to export or import your app configuration.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0e8713b34b0a80fb4f1d21795a03b548/eea4a/TinyCam-Monitor_11.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIBBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHhrxCh/8QAFxAAAwEAAAAAAAAAAAAAAAAAABARAf/aAAgBAQABBQIi0q//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAWEAADAAAAAAAAAAAAAAAAAAAQIDH/2gAIAQEABj8CET//xAAaEAEAAgMBAAAAAAAAAAAAAAABABEQITFB/9oACAEBAAE/IUvA0cMVVrpC3kJ//9oADAMBAAIAAwAAABC8P//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/EFf/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPxBH/8QAGxABAAICAwAAAAAAAAAAAAAAAQARITFhcbH/2gAIAQEAAT8QZ2gdQRqLA8hIBeWTeoKr3EJdT//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0e8713b34b0a80fb4f1d21795a03b548/e4706/TinyCam-Monitor_11.avif 230w", "/en/static/0e8713b34b0a80fb4f1d21795a03b548/d1af7/TinyCam-Monitor_11.avif 460w", "/en/static/0e8713b34b0a80fb4f1d21795a03b548/7f308/TinyCam-Monitor_11.avif 920w", "/en/static/0e8713b34b0a80fb4f1d21795a03b548/d7c22/TinyCam-Monitor_11.avif 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0e8713b34b0a80fb4f1d21795a03b548/a0b58/TinyCam-Monitor_11.webp 230w", "/en/static/0e8713b34b0a80fb4f1d21795a03b548/bc10c/TinyCam-Monitor_11.webp 460w", "/en/static/0e8713b34b0a80fb4f1d21795a03b548/966d8/TinyCam-Monitor_11.webp 920w", "/en/static/0e8713b34b0a80fb4f1d21795a03b548/af3f0/TinyCam-Monitor_11.webp 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0e8713b34b0a80fb4f1d21795a03b548/e83b4/TinyCam-Monitor_11.jpg 230w", "/en/static/0e8713b34b0a80fb4f1d21795a03b548/e41a8/TinyCam-Monitor_11.jpg 460w", "/en/static/0e8713b34b0a80fb4f1d21795a03b548/4d836/TinyCam-Monitor_11.jpg 920w", "/en/static/0e8713b34b0a80fb4f1d21795a03b548/eea4a/TinyCam-Monitor_11.jpg 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0e8713b34b0a80fb4f1d21795a03b548/4d836/TinyCam-Monitor_11.jpg",
              "alt": "TinyCam Monitor for your INSTAR IP Camera",
              "title": "TinyCam Monitor for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Your camera´s are now added to the app.`}</p>
    <p>{`Your cameras are now added to the app. Click on the Edit Button (pencil symbol) to make changes to the camera setup or click on the Return Button on the upper left corner to return to the app´s Start Screen.`}</p>
    <h3 {...{
      "id": "the-live-view-window",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#the-live-view-window",
        "aria-label": "the live view window permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Live View Window`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/11941df3d51a88254e99d1ae7904b572/eea4a/TinyCam-Monitor_12.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAGlZVNAkE//xAAbEAACAgMBAAAAAAAAAAAAAAABAgADEBETIf/aAAgBAQABBQInQVw2DfO8F/n/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAYEAADAQEAAAAAAAAAAAAAAAAAMqEQEf/aAAgBAQAGPwLurRBaf//EABoQAQACAwEAAAAAAAAAAAAAAAEAERBBcfD/2gAIAQEAAT8hN1qIUGDAu9wHVOMDb5yf/9oADAMBAAIAAwAAABCzP//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/EFf/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPxBH/8QAGxAAAwEAAwEAAAAAAAAAAAAAAAERITFxseH/2gAIAQEAAT8Q4kBdpZdFtmzCJqLY/gtaW7UqU/B9Ogv/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/11941df3d51a88254e99d1ae7904b572/e4706/TinyCam-Monitor_12.avif 230w", "/en/static/11941df3d51a88254e99d1ae7904b572/d1af7/TinyCam-Monitor_12.avif 460w", "/en/static/11941df3d51a88254e99d1ae7904b572/7f308/TinyCam-Monitor_12.avif 920w", "/en/static/11941df3d51a88254e99d1ae7904b572/d7c22/TinyCam-Monitor_12.avif 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/11941df3d51a88254e99d1ae7904b572/a0b58/TinyCam-Monitor_12.webp 230w", "/en/static/11941df3d51a88254e99d1ae7904b572/bc10c/TinyCam-Monitor_12.webp 460w", "/en/static/11941df3d51a88254e99d1ae7904b572/966d8/TinyCam-Monitor_12.webp 920w", "/en/static/11941df3d51a88254e99d1ae7904b572/af3f0/TinyCam-Monitor_12.webp 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/11941df3d51a88254e99d1ae7904b572/e83b4/TinyCam-Monitor_12.jpg 230w", "/en/static/11941df3d51a88254e99d1ae7904b572/e41a8/TinyCam-Monitor_12.jpg 460w", "/en/static/11941df3d51a88254e99d1ae7904b572/4d836/TinyCam-Monitor_12.jpg 920w", "/en/static/11941df3d51a88254e99d1ae7904b572/eea4a/TinyCam-Monitor_12.jpg 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/11941df3d51a88254e99d1ae7904b572/4d836/TinyCam-Monitor_12.jpg",
              "alt": "TinyCam Monitor for your INSTAR IP Camera",
              "title": "TinyCam Monitor for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Gehen Sie zurück zum Startbildschirm und wählen Sie Live View.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/95a33ddde4c93190c878903adee0b33b/eea4a/TinyCam-Monitor_13.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABYBAQEBAAAAAAAAAAAAAAAAAAEAAv/aAAwDAQACEAMQAAABXiZmVxKT/8QAGhAAAwEAAwAAAAAAAAAAAAAAAAECEQMSE//aAAgBAQABBQJ0JdjZHy7Hs9Vn/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Bp//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/AYf/xAAdEAACAQQDAAAAAAAAAAAAAAAAARECEiExQWGR/9oACAEBAAY/AndwapXZhL0iCVsyj//EABwQAAICAgMAAAAAAAAAAAAAAAERACExUUFhcf/aAAgBAQABPyErEUeTzGA7ODua17G5ctwjvDsXBEEuL2p//9oADAMBAAIAAwAAABAH7//EABYRAQEBAAAAAAAAAAAAAAAAAAARMf/aAAgBAwEBPxDSv//EABcRAQADAAAAAAAAAAAAAAAAAAABESH/2gAIAQIBAT8QjCn/xAAcEAEBAQEBAAMBAAAAAAAAAAABESEAMWGBkcH/2gAIAQEAAT8QHUhIkg9x3Z+8CRIlhjTXeS0B2U/nbUwxsm+cBzoURJPJ8dZDJowfR3//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/95a33ddde4c93190c878903adee0b33b/e4706/TinyCam-Monitor_13.avif 230w", "/en/static/95a33ddde4c93190c878903adee0b33b/d1af7/TinyCam-Monitor_13.avif 460w", "/en/static/95a33ddde4c93190c878903adee0b33b/7f308/TinyCam-Monitor_13.avif 920w", "/en/static/95a33ddde4c93190c878903adee0b33b/d7c22/TinyCam-Monitor_13.avif 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/95a33ddde4c93190c878903adee0b33b/a0b58/TinyCam-Monitor_13.webp 230w", "/en/static/95a33ddde4c93190c878903adee0b33b/bc10c/TinyCam-Monitor_13.webp 460w", "/en/static/95a33ddde4c93190c878903adee0b33b/966d8/TinyCam-Monitor_13.webp 920w", "/en/static/95a33ddde4c93190c878903adee0b33b/af3f0/TinyCam-Monitor_13.webp 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/95a33ddde4c93190c878903adee0b33b/e83b4/TinyCam-Monitor_13.jpg 230w", "/en/static/95a33ddde4c93190c878903adee0b33b/e41a8/TinyCam-Monitor_13.jpg 460w", "/en/static/95a33ddde4c93190c878903adee0b33b/4d836/TinyCam-Monitor_13.jpg 920w", "/en/static/95a33ddde4c93190c878903adee0b33b/eea4a/TinyCam-Monitor_13.jpg 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/95a33ddde4c93190c878903adee0b33b/4d836/TinyCam-Monitor_13.jpg",
              "alt": "TinyCam Monitor for your INSTAR IP Camera",
              "title": "TinyCam Monitor for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Go back to the Start Screen and choose Live View.`}</p>
    <p>{`The Multiview window allows you to keep an eye on all your cameras.`}</p>
    <p>{`Multiview Window: Every camera that you add to the app will be displayed in the Multiview window. The Video will be displayed with a low refresh rate to keep bandwidth requirements low. Simply touch the live video to access the corresponding cameras live stream.`}</p>
    <h3 {...{
      "id": "the-camera-view-window",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#the-camera-view-window",
        "aria-label": "the camera view window permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Camera View Window`}</h3>
    <p>{`You can access the Camera View by clicking on a camera video in the Multiview window. You will have access to different button layouts depending on the available camera functions of your camera model. Simply tap on the two arrows in the lower left corner to switch between different layouts.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0a6330ce9f17b3f2cc04c705adc50440/eea4a/TinyCam-Monitor_14.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABQBAQAAAAAAAAAAAAAAAAAAAAL/2gAMAwEAAhADEAAAAUbjCpxoh//EABkQAQADAQEAAAAAAAAAAAAAAAECAxEiAP/aAAgBAQABBQKE+JIesxm2GFrsnr//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwGn/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Bh//EABwQAAICAgMAAAAAAAAAAAAAAAABAhEiMRIhYf/aAAgBAQAGPwKEWZc2Orr0jhpGjpUf/8QAGhABAQEAAwEAAAAAAAAAAAAAAREAITGRUf/aAAgBAQABPyEwmccKdaVV3nrBIj0yCR0X7piAhJjVEe7/2gAMAwEAAgADAAAAEGA//8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEhMf/aAAgBAwEBPxDEGz//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREh/9oACAECAQE/EJrEsP/EABwQAQADAQEAAwAAAAAAAAAAAAEAESExUUFhof/aAAgBAQABPxAly4FQ6JeUtVLkCgCm/XSowxIghGfNR7VLbje5Obgg4e15cXb5xD9VP//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0a6330ce9f17b3f2cc04c705adc50440/e4706/TinyCam-Monitor_14.avif 230w", "/en/static/0a6330ce9f17b3f2cc04c705adc50440/d1af7/TinyCam-Monitor_14.avif 460w", "/en/static/0a6330ce9f17b3f2cc04c705adc50440/7f308/TinyCam-Monitor_14.avif 920w", "/en/static/0a6330ce9f17b3f2cc04c705adc50440/d7c22/TinyCam-Monitor_14.avif 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0a6330ce9f17b3f2cc04c705adc50440/a0b58/TinyCam-Monitor_14.webp 230w", "/en/static/0a6330ce9f17b3f2cc04c705adc50440/bc10c/TinyCam-Monitor_14.webp 460w", "/en/static/0a6330ce9f17b3f2cc04c705adc50440/966d8/TinyCam-Monitor_14.webp 920w", "/en/static/0a6330ce9f17b3f2cc04c705adc50440/af3f0/TinyCam-Monitor_14.webp 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0a6330ce9f17b3f2cc04c705adc50440/e83b4/TinyCam-Monitor_14.jpg 230w", "/en/static/0a6330ce9f17b3f2cc04c705adc50440/e41a8/TinyCam-Monitor_14.jpg 460w", "/en/static/0a6330ce9f17b3f2cc04c705adc50440/4d836/TinyCam-Monitor_14.jpg 920w", "/en/static/0a6330ce9f17b3f2cc04c705adc50440/eea4a/TinyCam-Monitor_14.jpg 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0a6330ce9f17b3f2cc04c705adc50440/4d836/TinyCam-Monitor_14.jpg",
              "alt": "TinyCam Monitor for your INSTAR IP Camera",
              "title": "TinyCam Monitor for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/311d7682ba6ccaee4757ba6e4d0b1b33/eea4a/TinyCam-Monitor_15.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFAv/EABUBAQEAAAAAAAAAAAAAAAAAAAID/9oADAMBAAIQAxAAAAGcwq4XNNin/8QAGxAAAQQDAAAAAAAAAAAAAAAAAQACERIDFCL/2gAIAQEAAQUCxiQJTx3aq2pRdY//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPwEZ/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Bh//EABoQAAMAAwEAAAAAAAAAAAAAAAABEQIDIaH/2gAIAQEABj8C4myJQZix3X6WH//EABoQAQADAQEBAAAAAAAAAAAAAAEAESFRMRD/2gAIAQEAAT8hzkOEofQx35m5D32JeyjLmyYL5P/aAAwDAQACAAMAAAAQwz//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREh/9oACAEDAQE/EIKUb0//xAAWEQEBAQAAAAAAAAAAAAAAAAAAAVH/2gAIAQIBAT8Q0kf/xAAdEAEBAAICAwEAAAAAAAAAAAABEQAhQVExYXGB/9oACAEBAAE/EI5BLzfOvOFBay2e93GDZbw0wulEQTz3w+8aRkblP0N4SguotZ//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/311d7682ba6ccaee4757ba6e4d0b1b33/e4706/TinyCam-Monitor_15.avif 230w", "/en/static/311d7682ba6ccaee4757ba6e4d0b1b33/d1af7/TinyCam-Monitor_15.avif 460w", "/en/static/311d7682ba6ccaee4757ba6e4d0b1b33/7f308/TinyCam-Monitor_15.avif 920w", "/en/static/311d7682ba6ccaee4757ba6e4d0b1b33/d7c22/TinyCam-Monitor_15.avif 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/311d7682ba6ccaee4757ba6e4d0b1b33/a0b58/TinyCam-Monitor_15.webp 230w", "/en/static/311d7682ba6ccaee4757ba6e4d0b1b33/bc10c/TinyCam-Monitor_15.webp 460w", "/en/static/311d7682ba6ccaee4757ba6e4d0b1b33/966d8/TinyCam-Monitor_15.webp 920w", "/en/static/311d7682ba6ccaee4757ba6e4d0b1b33/af3f0/TinyCam-Monitor_15.webp 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/311d7682ba6ccaee4757ba6e4d0b1b33/e83b4/TinyCam-Monitor_15.jpg 230w", "/en/static/311d7682ba6ccaee4757ba6e4d0b1b33/e41a8/TinyCam-Monitor_15.jpg 460w", "/en/static/311d7682ba6ccaee4757ba6e4d0b1b33/4d836/TinyCam-Monitor_15.jpg 920w", "/en/static/311d7682ba6ccaee4757ba6e4d0b1b33/eea4a/TinyCam-Monitor_15.jpg 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/311d7682ba6ccaee4757ba6e4d0b1b33/4d836/TinyCam-Monitor_15.jpg",
              "alt": "TinyCam Monitor for your INSTAR IP Camera",
              "title": "TinyCam Monitor for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can access the Camera View by clicking on a camera video in the Multiview window.`}</p>
    <h3 {...{
      "id": "bandwidth-conservation-jpg-mode",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#bandwidth-conservation-jpg-mode",
        "aria-label": "bandwidth conservation jpg mode permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bandwidth Conservation: JPG Mode`}</h3>
    <p>{`TinyCam Monitor does not support the h.264 video stream of HD cameras at the moment - all cameras are treated as MJPEG cameras which leads to high bandwidth demands in case of an HD camera model. But you can use the JPG mode to preserve your bandwidth. Open the Advanced Settings and search for the JPEG Image Address. You can use the following two addresses - the first one will grab the full resolution 1280x720 pixel snapshot, while the second one uses a compressed JPG file that usually is more than enough for a small smartphone screen:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`http://<IP or DDNS address of your camera>/tmpfs/snap.jpg 1280x720 Resolution

http://<IP or DDNS address of your camera>/tmpfs/auto.jpg 320x176 Resolution`}</code></pre></div>
    <TinyCards mdxType="TinyCards" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      